import Vue from "vue";
import Vuex from "vuex";
import { mapMutations, memory } from "ngt-frontend-core";
import { getField, updateField } from "vuex-map-fields";
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import entityTags from "./modules/entityTags";
import entityAttrs from "./modules/entityAttrs";

// helpers
const createAPIStream = (url, initialPage = 0, limit = 20) => {
  function _stream(_url, _initialPage, _limit) {
    return new Promise(resolve => {
      callBffAPI({
        url: `${_url}${
          _url.includes("?") ? "&" : "?"
        }page=${_initialPage}&limit=${_limit}`,
        method: "GET",
        cache: true
      })
        .then(result => {
          _initialPage++;
          resolve({
            data: result.data,
            next() {
              return _stream(_url, _initialPage, _limit);
            }
          });
        })
        .catch(error => {
          resolve({
            data: [],
            error: error
          });
        });
    });
  }
  return () => _stream(url, initialPage, limit);
};

const massDataLoader = (storeHere, url, initialPage = 0, limit = 100) => {
  return new Promise(resolve => {
    const stream = createAPIStream(url, initialPage, limit);
    async function _take(_stream) {
      const { data, next } = await _stream();
      data.forEach(item => {
        storeHere.push(item);
      });
      if (data.length < limit) {
        resolve();
        return;
      }
      return _take(next);
    }
    return _take(stream);
  });
};

// try to get saved application settings from localStorage or set a default one
const appSettings = {};
Object.assign(appSettings, {
  identities: {
    limit: {}
  },
  identitiyAttributes: {
    limit: {}
  },
  identitiyTags: {
    limit: {}
  },
  identitiyTokens: {
    limit: {}
  },
  resourceServers: {
    limit: {}
  },
  scopes: {
    limit: {}
  },
  clients: {
    limit: {}
  },
  dataProviders: {
    limit: {}
  },
  dataStores: {
    limit: {}
  },
  licenseTemplates: {
    limit: {}
  },
  licenses: {
    limit: {}
  },
  customers: {
    limit: {}
  },
  groups: {
    limit: {}
  },
  trips: {
    limit: {}
  },
  timeLocks: {
    limit: {}
  },
  groupAssets: {
    limit: {}
  },
  groupDrivers: {
    limit: {}
  },
  groupIdentities: {
    limit: {}
  },
  eventTypes: {
    limit: {}
  },
  eventHandlers: {
    limit: {}
  },
  eventListeners: {
    limit: {}
  },
  resourceServerDetailsAttrEditor: {
    limit: {}
  }
});
const appSettingsDefaults = {
  gdprCookie: false,
  identities: {
    limit: {
      sortBy: ["name"],
      sortDesc: [false]
    }
  },
  resourceServers: {
    limit: {}
  },
  scopes: {
    limit: {}
  },
  clients: {
    limit: {}
  },
  dataProviders: {
    limit: {}
  },
  dataStores: {
    limit: {}
  },
  customers: {
    limit: {}
  },
  groups: {
    limit: {}
  }
};
memory.getItem(
  process.env.VUE_APP_SETTINGS_STORE_VARIABLE,
  item => {
    if (item) {
      Object.assign(appSettings, item);
    } else {
      Object.assign(appSettings, appSettingsDefaults);
    }
  },
  function() {
    Object.assign(appSettings, appSettingsDefaults);
  }
);

Vue.use(Vuex);

const state = {
  customers: [],
  loading: false,
  user: false,
  tags: false,
  hide_toolbar: false,
  open_settings_dialog: false,
  canInstallPWA: false,
  page: "",
  pageSub: "",
  drawer: {
    open: false,
    clipped: true,
    mobileBreakPoint: 15000,
    fixed: false,
    permanent: false,
    mini: true
  },
  APIdata: {}, // for API response cache
  appSettings,
  /*** IMPORTANT BASE CONFIG ***/
  selected_resource: process.env.VUE_APP_SELECTED_RESOURCE,
  // API URL for identities handling
  idp_api_url: process.env.VUE_APP_IDP_API,
  // API URL for idp registration
  idp_reg_url: process.env.VUE_APP_IDP_REGISTRATION,
  // API URL for forgotten password
  idp_pwd_url: process.env.VUE_APP_IDP_PASSWORD_RESET,
  // API URL for Login Manager
  idp_loginMan_url: process.env.VUE_APP_IDP_LOGIN_MANAGER,
  // BASE URL for identities handling
  idp_base_url: process.env.VUE_APP_IDP_BASE_URL
  /*** END OF IMPORTANT BASE CONFIG ***/
};

export default new Vuex.Store({
  state,
  mutations: {
    updateField,
    ...mapMutations(state),
    device(state, setVal) {
      state.device = { ...state.device, ...setVal };
    },
    drawer(state, setVal) {
      state.drawer = { ...state.drawer, ...setVal };
    },
    setAppSetting(state, valueObject) {
      if (Object.prototype.hasOwnProperty.call(valueObject, "parent")) {
        state.appSettings[valueObject.parent][valueObject.key] =
          valueObject.value;
      } else {
        state.appSettings[valueObject.key] = valueObject.value;
      }
      memory.setItem(
        process.env.VUE_APP_SETTINGS_STORE_VARIABLE,
        state.appSettings
      );
    }
  },
  actions: {
    async getCustomers({ commit }) {
      commit("loading", true);
      // console.log("loading", true)
      const customers = [];
      try {
        await massDataLoader(
          customers,
          "/customers?sort=name:asc&fields=customerId,name,_uri"
        );
        commit("customers", customers);
      } catch {
        error => {
          commit("errorSnackbar", error);
          commit("customers", []);
        };
      } finally {
        commit("loading", false);
        // console.log("loading", false)
      }
    }
  },
  getters: {
    pageTitle: state => () => {
      let v = state.page;
      if (Array.isArray(state.page) && Array.isArray(state.pageSub)) {
        v = state.page.concat(state.pageSub);
      }
      return v;
    },
    getField,
    getTags: state => () => state.tags,
    customers: state => () =>
      state.customers.map(customer => {
        return {
          id: customer.customerId,
          text: customer.name,
          value: customer._uri
        };
      }),
    systemType: state => {
      const idp = state.idp_base_url;
      const [protocol, host] = idp.split("://");
      const [hostname] = host.split(":");

      if (hostname === "localhost") {
        return {
          hostname: "localhost",
          system: "localhost",
          env: "localhost",
          menuBgColor: "#424242"
        };
      }

      const [hostnamePart, ...domainParts] = hostname.split(".");
      const [system, ...hostParts] = hostnamePart.split("-");
      const env = ["prod", "dev"].includes(domainParts[0])
        ? domainParts[0]
        : hostParts.includes("test")
        ? "test"
        : "prod";

      return {
        hostname,
        system,
        env,
        menuBgColor: system === "dpp" ? "#848484" : "#636363"
      };
    }
  },
  modules: {
    entityTags,
    entityAttrs
  }
});
